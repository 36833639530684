/** @file Find components on the page and initialize them.
 */
import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';
import { supportArticle } from 'Components/templateComponents/support-article-components/content-component/clientlibs/js/supportArticle.class.js';
import { pressComponents } from 'Components/templateComponents/press-components/content-component/clientlibs/js/pressComponents.class.js';

const registerComponent = AEM.registerComponent;

/*
**************************************
*********** Dynamic Imports ***********
**************************************
*/

/**
  * Import Gaming Benchmarks.
  */
if (document.querySelectorAll('.cmp-gamingbenchmarks').length) {
    import('Components/gamingBenchmarks/clientlibs/js/gamingBenchmarks.class.js').then(({ gamingBenchmarks }) => {
        registerComponent('.cmp-gamingbenchmarks', gamingBenchmarks);
    });
}

/**
 * Import Driver Selector.
 */
if (document.querySelector('[data-component="coveo-browse-documentation"]')) {
    import('Components/driverSelector/clientlibs/js/driverSelector.class.js').then(({ DriverSelector }) => {
        registerComponent('[data-component="coveo-browse-documentation"]', DriverSelector);
    });
}

/**
 * Import Filtered Link List.
 */
if (document.querySelectorAll('.cmp-filtered-link-list').length) {
    import('Components/filteredlinklist/clientlibs/js/filteredlinklist.class.js').then(({ FilteredLinkList }) => {
        registerComponent('.cmp-filtered-link-list', FilteredLinkList);
    });
}


/**
 * Import DataTables.net component.
 */
if (document.querySelectorAll('.cmp-datatables').length) {
    import('Components/dataTables/clientlibs/js/dataTables.class.js').then(({ dataTables }) => {
        registerComponent('.cmp-datatables', dataTables);
    });
}

registerComponent('.support-article', supportArticle);

registerComponent('.press-components', pressComponents);

/**
 * Import product specifications component.
 */
if (document.querySelectorAll('.product-specs-container').length) {
    import (/* webpackChunkName: 'productSpecs' */'Components/templateComponents/product-components/product-specs/clientlibs/js/productSpecs.class.js').then(({ ProductSpecs }) => {
        registerComponent('.product-specs-container', ProductSpecs);
    });
}

if (document.querySelectorAll('.cmp-product-specs-table').length) {
    import (/* webpackChunkName: 'productSpecsTable' */'Components/templateComponents/product-components/product-specs-table/clientlibs/js/productSpecsTable.class.js').then(({ ProductSpecsTable }) => {
        registerComponent('.cmp-product-specs-table', ProductSpecsTable);
    });
}
